<template>
	<div class="page-container">
		<Head />
		<div class="page-banner" :style="{backgroundImage:'url('+(slidePic?slidePic[0]:banner)+')'}">
			<div class="container banner-align-right">
				<div class="banner-text wow fadeInDown">
					<div class="ch">{{slide.title?slide.title:'产品'}}</div>
					<div class="en">{{slide.ltitle?slide.ltitle:'PRODUCT'}}</div>
					<img src="../assets/image/down-arrow.png" alt="" />
				</div>
			</div>
		</div>
		<div v-if="thispage.autoproduct">
			<div class="index-section">
                <div class="index-section index-style-b">
                    <div class="container wow fadeInUp">
                        <div class="section-head flex">
                            <div class="flex_bd">
                                <div class="h2">{{pageName }}</div>
                                <!-- <p>{{thispage.titile_comment}}</p> -->
                            </div>
                            <!-- <el-button v-if="lang==='zh-cn'" type="info" plain>浏览完整目录</el-button>
                            <el-button v-else type="info" plain>Browse the full catalog</el-button> -->
                        </div>
                        <el-row :gutter="24" type="flex" class="pack-row">
                            <el-col :xs="12" :sm="8" :md="8" :lg="6" v-for="(ite,idx) in product_list" :key="idx">
                                <div class="pack-card hover-up"  @click="clickit(ite)">
                                    <div class="pack-photo scale">
                                        <img :src="ite.pic_address&&ite.pic_address.length>0?ite.pic_address.split(',')[0]:''" alt="" />
                                    </div>
                                    <div class="pack-grid">
                                        <div class="cell-title">{{ite.product_name}}</div>
                                        <div class="cell-desc">{{ite.specification}}</div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
		</div>
		<div v-else class="page-wrapper">
			<Content :pagecontent="thispage.items"/>
		</div>
		<Foot />
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	import Content from '../components/content.vue';
	import { mapState } from 'vuex';
	export default {
		name: 'Home',
		components: {
			Head,
			Foot,
			Content
		},
		data() {
			return {
				banner:require('../assets/image/serve_banner.jpg'),
				pid: this.$route.query.pid,
				cid: this.$route.query.cid,
				product_list: []
			};
		},
		computed:{
			...mapState({
				'lang':state=>state.app.currentLang,
				'webcontents':state=>state.app.webcontents,
				'current':state=>state.app.current,
				'en_id':state=>state.app.en_id
			}),
			thispage(){
				if(!this.current){
					var vid = this.webcontents.list.filter(item=>item.type == 'Products')[0].id
					// this.setCurrent(vid)
					return this.webcontents.list.filter(item=>item.id == vid)[0]
				}else{
					return this.webcontents.list.filter(item=>item.id == this.current )[0]
				}
			},
			slide(){
				let slide1 = null
				if(this.thispage){
					slide1 = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				}
				return (slide1 && slide1.length > 0) ?slide1[0]:'';
			},
			slidePic(){
				let slide1 = null
				if(this.thispage){
					slide1 = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				}
				return (slide1 && slide1.length > 0) ?slide1[0].pictures.split(','):null;
			},
			pageitems(){
				var itemlist=this.thispage.items.filter(ite=>ite.item_type !='slideshow')
				var items = []
				var ob = {}
				var pic = []
				var title, ltitle, content
				for (let it of itemlist){
					// console.log(JSON.stringify(it))
					if(it.item_type =='Three_column'){
						ltitle = this.lang == 'en-US'?it.ltitle_en.split("`"):it.ltitle.split("`")
						content = this.lang == 'en-US'?it.content_en.split("`"):it.content.split("`")
					// }else if(it.item_type !='picture' && it.item_type !='analysis'&&it.item_type !='onlytitle'){
					}else if( it.item_type !='analysis'&&it.item_type !='onlytitle'){
						// console.log(JSON.stringify(it))
						if(this.lang == 'en-US' &&it.content_en !='null'&&it.content_en){
							content = JSON.parse(it.content_en).replace(/[\n\r]/g,'<p>')
						}else if(it.content !='null'&&it.content){
							content = JSON.parse(it.content).replace(/[\n\r]/g,'<p>')
						}else{
							content = ''
						}
					}else{
						content=this.lang == 'en-US'?it.content_en:it.content
					}
					if(it.pictures && it.pictures.length>0){
						pic=it.pictures.split(',')
					}
					title = this.lang == 'en-US'?it.title_en :it.title
					ob = {title:title,ltitle:ltitle,pic:pic,item_type:it.item_type,content:content}
					items.push(ob)
				}
				return items
			},
			pageName(){
				if(this.thispage.children){
					let childs = this.thispage.children[0].list.filter(item=>item.cid===this.cid)
					return childs.length>0?childs[0].ltitle:''
				}else{
					return this.thispage.pagename_zh
				}
			}
		},
		watch: {
			$route: function (to, from) {
				if (to.query.cid !== from.query.cid) { 
					this.cid = to.query.cid
					this.getProductData()
				}
			}
		},
		created() {
			console.log(this.cid)
			console.log(this.pid)
			console.log(JSON.stringify(this.thispage))
			if(!this.thispage){
				this.$router.push({ path:'/'})
			}
			if(this.thispage.autoproduct ){
				this.getProductData()
			}
		},
		methods: {
			getProductData(){
				if(this.cid){
					let formData = {
						page_no: 1, 
						page_size: 30, 
						enterprise:this.en_id, 
						search_name: '',
						cate_id: this.cid,
					}
					this.$axios.post("https://jesway.cn/api/product/get_enter_product", formData).then(res=>{
						console.log("服务器返回", JSON.stringify(res))
						this.product_list = res.data.products
					})
				}
			},
			buttonEevent1(url){
				if('http' in url){
					window.open(url, '_blank')
				}else{
					this.$router.push({ path:url})
				}
			},
			clickit(item){
				this.$router.push({ path: '/ProductDetail', query:{prod_id:item.id}})
			}
		},
		mounted() {
			new this.$wow.WOW({live: false}).init();
		}
	}
</script>
